import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { styles } from './styles/styles';

// Web view
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

// Mobile view
import MHeader from './components/MHeader';
import MHome from './components/MHome';
import MServices from './components/MServices';
import MAboutUs from './components/MAboutUs';
import MContactUs from './components/MContactUs';
import MFooter from './components/MFooter';

import BackToTopButton from './components/BackToTopButton';

const mobileMaxWidth = 800;
const webMaxWidth = 1280;

const App = (props) => {
  const {width, height} = useWindowDimensions();
  let styleWidth;

  let [fontsLoaded] = useFonts({
    'Raleway-Medium': require('./assets/font/Raleway-Medium.ttf'),
    'Raleway-Bold': require('./assets/font/Raleway-Bold.ttf'),
    'Raleway-ExtraBold': require('./assets/font/Raleway-ExtraBold.ttf')
  });

  const isWeb = width > mobileMaxWidth? true : false;
  if(isWeb){
    styleWidth = width>=webMaxWidth? webMaxWidth:width;
  }else{
    styleWidth = width>=mobileMaxWidth? mobileMaxWidth:width;
  }

  return (
    <Router>
      <View style={{backgroundColor: '#F9F9F9'}}>
      <View style={styles(isWeb? webMaxWidth:mobileMaxWidth).container}>
        {isWeb? <Header /> : <MHeader />}
        <View style={styles(styleWidth).contentBox}>
          <Routes>
            <Route exact path='/' element={isWeb? <Home/>:<MHome/>}></Route>
            <Route exact path='/services' element={isWeb? <Services/>:<MServices/>}></Route>
            <Route exact path='/about' element={isWeb? <AboutUs/>:<MAboutUs/>}></Route>
            <Route exact path='/contact' element={isWeb? <ContactUs/>:<MContactUs />}></Route>
          </Routes> 
          {isWeb? <Footer /> : <MFooter />}
        </View> 
        <BackToTopButton />
      </View>
      </View>
    </Router>
  )
}

export default App