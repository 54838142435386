import { Animated } from "react-native";

const transitionTime = 500;

export const animateOneTimes = (fade1) => {
    Animated.sequence([
        Animated.timing(fade1, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        })
    ]).start()
}

export const animateTwoTimes = (fade1, fade2, transitionTime) => {
    Animated.sequence([
        Animated.timing(fade1, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        }),
        Animated.timing(fade2, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        })
    ]).start()
}

export const serviceAnimate = (fade1, fade2, t1, t2) => {
    Animated.sequence([
        Animated.timing(fade1, {
            toValue: 1,
            duration: t1,
            useNativeDriver: false
        }),
        Animated.timing(fade2, {
            toValue: 1,
            duration: t2,
            useNativeDriver: false
        })
    ]).start()
}

export const animateThreeTimes = (fade1, fade2, fade3) => {
    Animated.sequence([
        Animated.timing(fade1, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        }),
        Animated.timing(fade2, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        }),
        Animated.timing(fade3, {
            toValue: 1,
            duration: transitionTime,
            useNativeDriver: false
        })
    ]).start()
}