import React from 'react';
import { useEffect, useState } from 'react';
import { Pressable, Image, useWindowDimensions } from 'react-native';
import { styles } from '../styles/styles';

import arrow from '../assets/icon/icon-04.png'

function BackToTopButton() {

    const {width, height} = useWindowDimensions();
    const styleWidth = width>=1280? 1280:width;
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.pageYOffset > 1) {
                setShowButton(true);
            }else{
                setShowButton(false);
            }
        })
        
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
          top:0,
          behavior: 'smooth'
        });
    };

    return (
        <>
            {showButton && (
                <Pressable onPress={scrollToTop} style={styles(styleWidth).backToTop}>
                    <Image source={arrow} style={styles(styleWidth).backToTop_btn}/>
                </Pressable>
            )}
        </>
    )
}

export default BackToTopButton
