import React, { useEffect } from 'react';
import { View, StyleSheet, Text, Image, Dimensions } from 'react-native';
import { normalize } from '../utils/normalize';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import Logo from '../assets/logo1.png';

const maxWidth = 1280;

function Word (props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }
    
    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    if(props.clicked){
        return (
            <View style={{flexDirection:'row'}}>
                <View style={styles(styleWidth).smalldot} /> 
                <Text style={styles(styleWidth).contentclick}>{props.text}</Text>
            </View>
        )
    }else{
        return <Text style={styles(styleWidth).content}>{props.text}</Text>
    }
}


export default class Header extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            isClicked: {
                home: false,
                service: false,
                about: false,
                contact: false,
            },
            width: Dimensions.get("window").width
        };

        let path = window.location.pathname;
        
        switch(path){
            case '/services':
                this.state.isClicked.service = true;
                break;
            case '/about':
                this.state.isClicked.about = true;
                break;
            case '/contact':
                this.state.isClicked.contact = true;
                break;
            default:
                this.state.isClicked.home = true;
        }

        this.hanleClick = this.hanleClick.bind(this);
    }

    hanleClick(index){
        let newState = this.state.isClicked;
        let name = Object.keys(newState);

        for(let a = 0; a< name.length; a++) {
            newState[name[a]] = (index == name[a])? true:false
        }

        this.setState({isClicked: newState});
    }

    handleResize() {
        if(this._isMounted){
            let currentWidth = Dimensions.get('window').width;
            let newWidth = currentWidth >= 1280? 1280:currentWidth;
            this.setState({width:newWidth});
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.handleResize();
        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        return(
            <View style={{alignContent:'center', flexWrap:'wrap', display:'flex', justifyContent:'center', zIndex:5, width:'100%', margin:'auto'}}>
                <View style={styles(this.state.width).container}>
                    <View style={{flex: 2}}>
                        <Image source={Logo} style={styles(this.state.width).logo} resizeMode='contain'/>
                    </View>
                    
                    <View style={{marginLeft:normalize(50,this.state.width),marginRight:normalize(55,this.state.width), flexDirection:'row', flex:3}}>
                        <View style={styles(this.state.width).content}>
                            <Link to='/' style={{ textDecoration: 'none' }} onClick={() => this.hanleClick('home')}>
                                <Word text='Home' clicked={this.state.isClicked.home}/>
                            </Link>
                        </View>

                        <View style={styles(this.state.width).content}>
                            <Link to='/services' style={{ textDecoration: 'none' }} onClick={() => this.hanleClick('service')}>
                                <Word text='Our Services' clicked={this.state.isClicked.service}/>
                            </Link>
                        </View>

                        <View style={styles(this.state.width).content}>
                            <Link to='/about' style={{ textDecoration: 'none' }} onClick={() => this.hanleClick('about')}>
                                <Word text='About Us' clicked={this.state.isClicked.about}/>
                            </Link>
                        </View>

                        <View style={styles(this.state.width).content}>
                            <Link to='/contact' style={{ textDecoration: 'none' }} onClick={() => this.hanleClick('contact')}>
                                <Word text='Contact Us' clicked={this.state.isClicked.contact}/>
                            </Link>
                        </View>
                    </View>
                        
                </View>
                    
                    {/* <View>
                        <Routes>
                            <Route exact path='/' element={<Home/>}></Route>
                            <Route exact path='/services' element={<Services/>}></Route>
                            <Route exact path='/about' element={<AboutUs/>}></Route>
                            <Route exact path='/contact' element={<ContactUs/>}></Route>
                        </Routes>
                    </View> */}
            </View>
            
        )
    }
}

const styles = (width) => StyleSheet.create({
    container: {
        width: width,
        flexDirection: 'row',
        justifyContent:'center',
        backgroundColor:'#FFFFFF',
        position:'fixed',
        top: 0,
        zIndex:5,
    },
    content: {
        flex:1,
        alignItems: 'center',
        margin: 'auto',
        fontFamily: 'Raleway-Medium',
        fontSize: normalize(20,width),
        color: '#AAAAAA',
    },
    logo: {
        width: normalize(100,width),
        height: normalize(100,width),
        marginLeft:normalize(60,width)
    },
    smalldot: {
        width:5,
        height:5,
        backgroundColor: '#FF9F1D',
        margin:'auto',
        alignItems:'center',
        marginRight: normalize(10,width)
    },
    contentclick:{
        flex:1,
        alignItems: 'center',
        margin: 'auto',
        fontFamily: 'Raleway-Bold',
        fontSize:normalize(20,width),
        color: '#000000'
    }
})

