import React, { useEffect } from 'react';
import { View, StyleSheet, Text, Image, Pressable, Dimensions } from 'react-native';
import { normalize } from '../utils/normalize';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import Logo from '../assets/logo1.png';
import navBtn from '../assets/icon/icon-09.png';
import navCloseBtn from '../assets/icon/icon-10.png';

const maxWidth = 800

function Word (props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    if(props.clicked){
        return (
            <View style={{flexDirection:'row', paddingLeft:normalize(30,styleWidth)}}>
                <View style={styles(styleWidth).smalldot} /> 
                <Text style={styles(styleWidth).contentclick}>{props.text}</Text>
            </View>
        )
    }else{
        return <Text style={styles(styleWidth).content}>{props.text}</Text>
    }
}

export default class MHeader extends React.Component{
    _isMounted = true;

    constructor(props){
        super(props);
        this.state = {
            isClicked: {
                home: false,
                service: false,
                about: false,
                contact: false,
            },
            showDrawer: false,
            width: Dimensions.get("window").width
        };

        let path = window.location.pathname;
        
        switch(path){
            case '/services':
                this.state.isClicked.service = true;
                break;
            case '/about':
                this.state.isClicked.about = true;
                break;
            case '/contact':
                this.state.isClicked.contact = true;
                break;
            default:
                this.state.isClicked.home = true;
        }

        this.hanleClick = this.hanleClick.bind(this);
        this.handlePress = this.handlePress.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    hanleClick(index){
        if(this._isMounted){
            let newState = this.state.isClicked;
            let name = Object.keys(newState);

            for(let a = 0; a< name.length; a++) {
                newState[name[a]] = (index == name[a])? true:false
            }

            this.setState({isClicked: newState});
            this.setState({showDrawer: false});
        }
    }

    handleResize() {
        if(this._isMounted){
            let currentWidth = Dimensions.get('window').width;
            let newWidth = currentWidth > 800? 800:currentWidth;
            this.setState({width:newWidth});
        }
    }

    handlePress() {
        if(this._isMounted){
            this.setState({showDrawer: true});
        }
    }

    handleClose() {
        if(this._isMounted){
            this.setState({showDrawer: false});
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(this._isMounted){
            this.handleResize();
            window.addEventListener('resize', this.handleResize.bind(this));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        return(
            <View style={{alignContent:'center', flexWrap:'wrap', display:'flex', justifyContent:'center', zIndex:5, width:'100%', margin:'auto'}}>
                <View style={styles(this.state.width).container}>
                    <View style={{flex: 1}}>
                        <Image source={Logo} style={styles(this.state.width).logo} resizeMode='contain'/>
                    </View>

                    <Pressable onPress={this.handlePress}>
                        <Image source={navBtn} style={{width: normalize(25,this.state.width), height:normalize(25,this.state.width), margin:'auto', marginRight:normalize(60, this.state.width)}} resizeMode='contain'/>
                    </Pressable>

                    {
                        this.state.showDrawer && 
                        <>
                            <Pressable onPress={this.handleClose} style={styles(this.state.width).transparentBackgroud}></Pressable>
                        
                            <View style={{opacity: 1.0,width: '70%', height:Dimensions.get('screen').height , backgroundColor:"#FFFFFF", position:'absolute', right:0, top:0}}>
                                <Pressable onPress={this.handleClose}>
                                    <Image source={navCloseBtn} resizeMode='cover' style={{width:normalize(40, this.state.width), height:normalize(40, this.state.width), alignSelf:'flex-end', margin:normalize(50, this.state.width)}}  />
                                </Pressable>
                                <View style={styles(this.state.width).content}>
                                <Link to='/' style={{textDecoration:'none'}} onClick={() => this.hanleClick('home')}>
                                    <Word text='Home' clicked={this.state.isClicked.home}/>
                                </Link>
                                </View>
                                
                                <View style={styles(this.state.width).content}>
                                <Link to='/services' style={{textDecoration:'none'}} onClick={() => this.hanleClick('service')}>
                                    <Word text='Our Services' clicked={this.state.isClicked.service}/>
                                </Link>
                                </View>
                                
                                <View style={styles(this.state.width).content}>
                                <Link to='/about' style={{textDecoration:'none'}} onClick={() => this.hanleClick('about')}>
                                    <Word text='About Us' clicked={this.state.isClicked.about}/>
                                </Link>
                                </View>
                                
                                <View style={styles(this.state.width).content}>
                                <Link to='/contact' style={{textDecoration:'none'}} onClick={() => this.hanleClick('contact')}>
                                    <Word text='Contact Us' clicked={this.state.isClicked.contact}/>
                                </Link>
                                </View>
                            </View>
                        </>
                    }
                        
                </View>
                    
                    {/* <View>
                        <Routes>
                            <Route exact path='/' element={<Home/>}></Route>
                            <Route exact path='/services' element={<Services/>}></Route>
                            <Route exact path='/about' element={<AboutUs/>}></Route>
                            <Route exact path='/contact' element={<ContactUs/>}></Route>
                        </Routes>
                    </View> */}
            </View>
            
        )
    }
}

const styles = (width) => StyleSheet.create({
    container: {
        width: '100%',
        margin:'auto',
        alignItems:'center',
        flexDirection: 'row',
        justifyContent:'center',
        backgroundColor:'#FFFFFF',
        position:'fixed',
        top: 0,
        zIndex:5,
        shadowColor: "#000",
        shadowOffset: {
	        width: 0,
	        height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    content: {
        zIndex:5,
        //flex:1,
        alignItems: 'flex-start',
        padding:normalize(50,width),
        paddingBottom:normalize(20,width),
        paddingTop:normalize(20,width),
        //margin: 'auto',
        fontFamily: 'Raleway-Medium',
        fontSize: normalize(20,width),
        color: '#AAAAAA',
    },
    logo: {
        width: normalize(100,width),
        height: normalize(100,width),
        marginLeft:normalize(60,width)
    },
    smalldot: {
        width:normalize(10,width),
        height:normalize(10,width),
        backgroundColor: '#FF9F1D',
        //paddingLeft:50,
        margin:'auto',
       // alignItems:'center',
        marginRight: normalize(10,width)
    },
    contentclick:{
        //flex:1,
        alignItems: 'flex-start',
        //padding:50,
        //margin: 'auto',
        fontFamily: 'Raleway-Bold',
        fontSize:normalize(20,width),
        color: '#000000'
    },
    transparentBackgroud:{
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0.4,
        backgroundColor: '#000',
        height:Dimensions.get('screen').height,
        width: '100%'
    }
})

