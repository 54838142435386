import React, { useEffect, useRef, useState } from 'react';
import { Animated, View, Text, ImageBackground, StyleSheet, Dimensions} from 'react-native';
import { normalize } from '../utils/normalize';
import { animateOneTimes, animateThreeTimes } from '../utils/animation';

import titleBg from '../assets/image/bg-05.png';
import valueBg from '../assets/image/bg-06.png';
import charA from '../assets/image/A.png';
import charC from '../assets/image/C.png';

const maxWidth = 800;

const aboutUsContent 
    = 'ACA Global Logistics Sdn Bhd is a Malaysia-based company established in 2016. Starting primarily on trucking services, we are now moving rapidly in expanding our services into sea freight and air freight services to accommodate current and future market demands as well as customers needs.'
    + '\n' + '\n' 
    + 'We are a one-stop logistics company specialising in cross border transportation between Malaysia and Singapore, international logistics and project shipments and mover services for relocation purposes.'+'\n'+ 'At ACA Global Logistics Sdn Bhd, we believe that our enthusiasm and knowledge of this industry could significantly benefit our customers, and together, as allies, we strive towards excellence.'

const missionContent = 'To deliver quality and streamlined transportation and logistic services across the globe.'
const vissionContent = 'To incessantly innovate our logistic services for a modern and sustainable approach to emerge as a global leader in this field.'

const value = [
    {
        src: charA,
        title: "Adaptive",
        desc: "Able to adapt according to the customers' requirements."
    },
    {
        src: charC,
        title: "Committed",
        desc: "Ensuring our services are constantly top-notch."
    },
    {
        src: charA,
        title: "Accountability",
        desc: "Positive environment to build trust and maintain alliances."
    }
]

function ValueCard(props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    let title = props.content.title;
    let a = title.length;
    let firstChar = title[0];
    let str = title.slice(1, a)

    return(
        <ImageBackground source={props.content.src} resizeMode='contain' style={styles(styleWidth).valueCard} imageStyle={{opacity:0.5}}>
            <Text style={styles(styleWidth).valueCardTitle}>
                <Text style={{borderBottomWidth:4, borderColor:'#000000',paddingBottom:5}}>{firstChar}</Text>
                {str}
            </Text>
            <Text style={styles(styleWidth).valueContent}>
                {props.content.desc}
            </Text>
        </ImageBackground>       
        
    )
}


function MAboutUs() {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let height = Dimensions.get('window').height;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            height = Dimensions.get('window').height;     
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    const fade1 = useRef(new Animated.Value(0)).current;
    const fade2 = useRef(new Animated.Value(0)).current;
    const fade3 = useRef(new Animated.Value(0)).current;
    const fade4 = useRef(new Animated.Value(0)).current;
    const [visible, setVisible] = useState(0);
    const [onTop, setOnTop] = useState(0);

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);

        if(window.pageYOffset > 0){
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
            window.addEventListener('scroll', () => {
                if(window.pageYOffset == 0){
                    setOnTop(1);
                    animateOneTimes(fade1);
                    return;
                }
            })
        }else{
            setOnTop(1);
            animateOneTimes(fade1);
        }
        
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    useEffect(() => {
        if(visible){
            animateThreeTimes(fade4, fade2, fade3);
        }
    },[visible])

    const handleOnLayout = (e) => {
        let layout = e.nativeEvent.layout;

        if(layout.y > 0){
            if(layout.y > height){
                window.addEventListener('scroll', () => {
                    if(onTop){
                        if(window.pageYOffset > (layout.y-layout.height)) {
                            setVisible(1);
                        }
            
                    }
                })
            }else{
                setVisible(1);
                // animated();
            }
        }
    }

    return (
        <View style={{margin:'auto', width:'100%', maxWidth:styleWidth}}>
            <View>
                <ImageBackground source={titleBg} resizeMode='cover' style={{width:'100%'}}>
                <Animated.View 
                    style={{
                        opacity: fade1,
                        transform: [{
                            translateX: fade1.interpolate({
                                inputRange: [0, 1],
                                outputRange: [150, 0]
                            })
                        }]
                    }}
                >
                    <View style={styles(styleWidth).titleBox}>
                        <Text style={styles(styleWidth).title}>About Us</Text>
                        <Text style={styles(styleWidth).companyName}>
                            <Text style={styles(styleWidth).bottomBorderText}>
                                A
                            </Text>
                            CA Global Logistics
                        </Text>
                        <Text style={styles(styleWidth).content}>{aboutUsContent}</Text>
                    </View>
                </Animated.View>
                </ImageBackground>
            </View>

            <View style={{flexDirection:'column', justifyContent:'center',width:'100%', margin:'auto'}}>
                <View style={styles(styleWidth).missionBox}>
                    <Text style={styles(styleWidth).visionMissionTitle}>
                        Our <Text style={styles(styleWidth).bottomBorderText}>Mi</Text>
                        ssion
                    </Text>
                    <Text style={styles(styleWidth).visionMissionContent}>{missionContent}</Text>
                </View>
                
                <View style={styles(styleWidth).visionBox}>  
                    <Text style={styles(styleWidth).visionMissionTitle}>
                    Our <Text style={styles(styleWidth).bottomBorderText}>Vi</Text>sion  
                    </Text>
                    <Text style={styles(styleWidth).visionMissionContent}>{vissionContent}</Text>
                </View>
            </View>

            <View key={onTop} onLayout={e => {handleOnLayout(e)}}>
                <ImageBackground source={valueBg} resizeMode='cover'>
                    <View style={styles(styleWidth).valueBox}>
                        <Text style={styles(styleWidth).valueTitle}>Our Values</Text>
                        <Animated.View 
                            style={{
                                opacity: fade4,
                                transform: [{
                                    translateX: fade4.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [-150, 0]
                                    })
                                }]
                            }}
                        >
                            <ValueCard content={value[0]} />
                        </Animated.View>
                    
                        <Animated.View 
                            style={{
                                opacity: fade2,
                                transform: [{
                                    translateX: fade2.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [-150, 0]
                                    })
                                }]
                            }}
                        >
                            <ValueCard content={value[1]} />
                        </Animated.View>
                    
                        <Animated.View 
                            style={{
                                opacity: fade3,
                                transform: [{
                                    translateX: fade3.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: [-150, 0]
                                    })
                                }]
                            }}
                        >
                            <ValueCard content={value[2]} />
                        </Animated.View>
                    </View>
                </ImageBackground>
            </View>
        </View>
    )
}

const styles = (width) => StyleSheet.create({
    titleBox:{
        width:normalize(650,width), 
        backgroundColor:'#FFFFFF', 
        padding:normalize(80,width), 
        paddingLeft:normalize(100,width),
        margin:normalize(75,width),
        shadowColor: "#000",
        shadowOffset: {
	        width: 5,
	        height: 0,
        },
        shadowOpacity: 0.1,
        shadowRadius: 40,
    },
    title:{
        fontFamily:'Raleway-ExtraBold',
        fontSize:normalize(60,width),
        color:'#0F0F0F',
        paddingBottom:normalize(20,width)
    },
    companyName:{
        fontFamily:'Raleway-Bold',
        fontSize:normalize(35, width),
        color:'#0F0F0F',
        paddingBottom:normalize(50,width)
    },
    content:{
        fontFamily:'Raleway-Medium',
        color:'#AAAAAA',
        fontSize:normalize(25,width),
        lineHeight:normalize(35,width)
    },
    missionBox:{
        backgroundColor:'#262626',
        flex:1, 
        padding:normalize(55,width),
        paddingTop:normalize(100,width),
        alignItems:'center',
        justifyContent:'center'
    },
    visionBox:{
        backgroundColor:'#505050', 
        flex:1, 
        padding:normalize(55,width),
        paddingTop:normalize(100,width),
        alignItems:'center',
        justifyContent:'center'
    },
    visionMissionTitle:{
        color:'#FFFFFF', 
        fontSize:normalize(50,width), 
        textAlign:'center', 
        fontFamily:'Raleway-Medium'
    },
    visionMissionContent:{
        color:'#AAAAAA',
        fontSize:normalize(25,width), 
        textAlign:'center', 
        fontFamily:'Raleway-Medium',
        padding:normalize(30,width),
        lineHeight:normalize(40,width)
    },
    bottomBorderText:{
        borderBottomWidth:5, 
        borderColor:'#FF9F1D',
        paddingBottom:5
    },
    valueBox:{
        padding:normalize(30,width), 
        paddingTop:normalize(150,width), 
        paddingBottom:normalize(100,width)
    },
    valueTitle:{
        fontFamily:'Raleway-ExtraBold',
        fontSize:normalize(60,width),
        color:'#FFFFFF',
        paddingBottom:normalize(20,width),
        textAlign:'center'
    },
    valueCard:{
        backgroundColor:'#FF9F1D',
        height:normalize(250,width),
        margin:normalize(50,width), 
        marginBottom:normalize(50,width), 
        marginTop:normalize(30,width),
        padding:normalize(60,width),
        shadowColor: '#FF9F1D',
        shadowOffset:{width:0, height:20},
        shadowRadius: 40,
        shadowOpacity: 0.25
    },
    valueCardTitle: {
        fontSize:normalize(30,width), 
        fontFamily:'Raleway-Bold', 
        color:'#FFFFFF',
        marginBottom:normalize(40,width)
    },
    valueContent: {
        fontFamily: 'Raleway-Medium', 
        color:'#E8E8E8', 
        fontSize:normalize(20, width)
    }
})

export default MAboutUs