import React, { useEffect } from 'react'
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native';
import { normalize } from '../utils/normalize';

import addressLogo from '../assets/icon/icon-05.png';
import emailLogo from '../assets/icon/icon-06.png';
import contactLogo from '../assets/icon/icon-07.png';

const address = '78-01, Taman, Jalan Kota 2/3, Cahaya Kota Puteri, 81750 Masai, Johor.';
const email = 'sales@acaglobal.com.my';
const contact = '(+60) 14 - 389 6318';

const maxWidth = 1280;

function Info(props) {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    return (
        <View style={{flexDirection:'row'}}>
           <Image source={props.src} fadeDuration={0} style={styles(styleWidth).detailLogo} resizeMode='contain' />
            <Text style={styles(styleWidth).font}>{props.text}</Text>
        </View>
    )
}

function Footer() {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }
    
    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);
    

    return (
        <View style={styles(styleWidth).container}>
            <View style={styles(styleWidth).contentbox}>
                <View style={styles(styleWidth).detailbox}>
                    <View style={styles(styleWidth).logodiv}>
                        <Image style={styles(styleWidth).companylogo} resizeMode='contain' source={require('../assets/logo1.png')} />
                        <Text style={styles(styleWidth).companyName}>ACA Global Logistics</Text>
                    </View>
                    
                    <View style={styles(styleWidth).detaildiv}>
                        <Info text={address} src={addressLogo}/>
                        <Info text={email} src={emailLogo}/>
                        <Info text={contact} src={contactLogo}/>
                    </View>
                </View>

                <View style={styles(styleWidth).line} />
                
                <View style={styles(styleWidth).detailbox}>
                    <Text style={styles(styleWidth).lowerTextLeft}>© 2019 - 2021 ACA Global Logistics Sdn Bhd (1319909-M). All Rights Reserved.</Text>
                    <Text style={styles(styleWidth).lowerTextRight}>Designed by ACA Global Logistics</Text>
                </View>
            </View>
        </View>
    )
}

const styles = (width) => StyleSheet.create({
    container: {
        backgroundColor: '#E8E8E8',
        width: '100%',
        margin:'auto',
    },
    contentbox: {
        marginTop:normalize(65,width),
        marginLeft:normalize(70,width),
        marginRight:normalize(60,width),
        marginBottom:normalize(40,width),
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    detailbox: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    logodiv: {
        flex: 1,
    },
    detaildiv: {
        flex:1
    },
    companyName:{
        color:'#0F0F0F',
        fontFamily: 'Raleway-ExtraBold',
        fontSize:normalize(25, width),
        textAlign: 'left',
        fontWeight: 'bold',
    },
    font: {
        flex: 0.9,
        color: '#505050',
        fontFamily: 'Raleway-Medium',
        fontSize:normalize(20,width),
        padding: normalize(15,width),
        textAlign:'left'
    },
    line: {
        marginTop:normalize(75,width),
        marginBottom:normalize(50,width),
        borderBottomColor: '#AAAAAA',
        borderBottomWidth: 1
    },
    companylogo: {
        width: normalize(100, width),
        height: normalize(50, width),
    },
    detailLogo:{
        width:normalize(35,width), 
        height: normalize(35, width), 
        margin:'auto',
        flex: 0.1,
        alignItems:'center'
    },
    lowerTextLeft: {
        flex: 1,
        color: '#AAAAAA',
        fontFamily: 'Raleway-Medium',
        fontSize:normalize(15,width),
        textAlign:'left'
    },
    lowerTextRight:{
        flex: 1,
        color: '#AAAAAA',
        fontFamily: 'Raleway-Medium',
        fontSize:normalize(15,width),
        textAlign:'right'
    }
    
})

export default Footer
