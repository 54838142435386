import React, {useEffect, useRef, useState, useCallback} from 'react';
import { Animated, View, Text, StyleSheet, Pressable, Dimensions, ScrollView} from 'react-native';

import { normalize } from '../utils/normalize';
import { serviceAnimate } from '../utils/animation';


import serviceA from '../assets/image/img-12.png';
import serviceB from '../assets/image/img-13.png';
import serviceC from '../assets/image/img-14.png';
import serviceD from '../assets/image/img-15.png';

const maxWidth = 800;

const imgSrc = [serviceA, serviceB, serviceC, serviceD];
const serviceTitle = ['Sea Freight International & Domestic (East Malaysia)',
                        'Land Freight', 'Air Freight International',
                        'Custom Brokerage'];
const serviceContent = {
    a:['FCL Import And Export','LCL Import And Export'],
    b:['Domestic Delivery', 'Cross Border Trucking', 
        'Project Transportation (low loader, crane)','Container Haulage'],
    c:['Consolidation', 'Chartered / Freighter'],
    d:['Export / Import Declaration', 'SG Permit Declaration']
};

function Desc(props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    const content = props.content;
    
    const listContent = content.map((con) => 
        <View key={con} style={{flexDirection:'row'}}>
            <View style={styles(styleWidth).smalldot} />
            <Text style={styles(styleWidth).descContent}>{con}</Text>
        </View>
    );
    return(
        <View>
            {listContent}
        </View>
    )
}

export default function MServices(){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;
    let fadeAnim = useRef(new Animated.Value(0)).current;
    let fade1 = useRef(new Animated.Value(0)).current;
    let myRef = useRef(null);

    const [isClicked, setClicked] = useState({
        a: true,
        b: false,
        c: false,
        d: false
    })
    const [imageSrc, setImageSrc] = useState(imgSrc[0]);
    const [content, setContent] = useState(serviceContent['a']);
    const [onTop, setOnTop] = useState(0);

    // for option
    const [a_xposition, setAPosition] = useState(0);
    const [b_xposition, setBPosition] = useState(0);
    const [c_xposition, setCPosition] = useState(0);
    const [d_xposition, setDPosition] = useState(0);

    const [a_width, setAWidth] = useState(0);
    const [b_width, setBWidth] = useState(0);
    const [c_width, setCWidth] = useState(0);
    const [d_width, setDWidth] = useState(0);

    const [isLoading, setLoading] = useState(0);
    const [isScroll, setScroll] = useState(1);
    const [xScroll, setXSCroll] = useState(0);
    
    const threshold = styleWidth * 0.02;
    const waitingTime = 700 // for option
    const imgWaitingTime = 300 // for scroll image

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
            threshold = styleWidth*0.05;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        if(window.pageYOffset > 0){
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
            window.addEventListener('scroll', () => {
                if(window.pageYOffset == 0){
                    setOnTop(1);
                    return;
                }
            })
        }else{
            setOnTop(1);
        }

        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);
    
    // for transition
    useEffect(() => {
        //reset the value of opacity
        fade1.setValue(0);
        fadeAnim.setValue(0);

        if(onTop) serviceAnimate(fade1, fadeAnim, 300, 350);
    }, [fade1, fadeAnim, content, onTop]);

    useEffect(() => {
        if(isClicked['a']){
            myRef.current.scrollTo({
                x:0,
                animated: true
            })
        }else if(isClicked['b']){
            myRef.current.scrollTo({
                x:b_xposition-(a_width/2),
                animated: true
            })
        }else if(isClicked['c']){
            myRef.current.scrollTo({
                x:c_xposition-((a_width + b_width)/3),
                animated: true
            })
        }else if(isClicked['d']){
            myRef.current.scrollTo({
                x:d_xposition-((a_width + b_width + c_width)/4),
                animated: true
            })
        }
    },[isClicked]) 

    const handleClick = (index) => {
        setLoading(1);
        let newState = isClicked;
        let newSrc, newContent;
        let name = Object.keys(newState);

        // scroll the option name to center
        if(!isLoading){
        switch(index){
            case 'a':
                myRef.current.scrollTo({
                    x:0,
                    animated: true
                })
                
                setTimeout(()=>{
                    setLoading(0);
                },waitingTime)
                break;

            case 'b':
                myRef.current.scrollTo({
                    x:b_xposition-(a_width/2),
                    animated: true
                })
                
                setTimeout(()=>{
                    setLoading(0);
                },waitingTime)
                break;

            case 'c':
                myRef.current.scrollTo({
                    x:c_xposition-((a_width + b_width)/3),
                    animated: true
                })
               
                setTimeout(() => {
                    setLoading(0);
                }, waitingTime);
                break;

            case 'd':
                myRef.current.scrollTo({
                    x:d_xposition-((a_width + b_width + c_width)/4),
                    animated: true
                })
                
                setTimeout(() => {
                    setLoading(0);
                }, waitingTime);
                break;

            default:
                myRef.current.scrollTo({
                    x:0,
                    animated: true
                })
                
                break;
        }

        for(let a = 0; a< name.length; a++) {
            newState[name[a]] = (index == name[a])? true:false
        }

        for(let b = 0; b < name.length; b++) {
            if(index == name[b]){
                newSrc = imgSrc[b]
                newContent = serviceContent[index];
                break;
            }
        }

        setClicked(newState);
        setImageSrc(newSrc);
        setContent(newContent);
    }
    
    }

    const handleOnLayout = (e, index) => {
        let layout = e.nativeEvent.layout;
        switch (index){
            case 'a':
                setAPosition(layout.x);
                setAWidth(layout.width);
                break;
            case 'b':
                setBPosition(layout.x);
                setBWidth(layout.width);
                break;
            case 'c':
                setCPosition(layout.x);
                setCWidth(layout.width);
                break;
            case 'd':
                setDPosition(layout.x);
                setDWidth(layout.width);
                break;
            default:
                break;
        }
    }

    const [startPoint, setStartPoint] = useState(0);
    const [changeable, setChangeable] = useState(1);

    const handleStart = (e) => {
        // console.log('START');
        let xOffset = e.nativeEvent.pageX;
        setStartPoint(xOffset);

    }

    const handleMoving = (e) => {
        // console.log('MOVING');
        // console.log(`starting point: ${startPoint}`)
        let currentPoint = e.nativeEvent.pageX;
        let direction = currentPoint - startPoint
        let distance = Math.abs(direction)

        // current - start < 0 => go to next
        // current - start > 0 => previous
        // console.log(`starting point: ${startPoint.toFixed(2)} \ncurrent point: ${currentPoint.toFixed(2)} \ndirection: ${direction.toFixed(2)} \nthreshold: ${threshold}`)

        if(changeable){
            if(distance > threshold){
                let currentIndex;
                let keys = Object.keys(isClicked);
                Object.keys(isClicked).forEach((key, index) => {
                    if(isClicked[key]) currentIndex = index;
                });
                // console.log(`current index: ${currentIndex} keys: ${keys[currentIndex]}`)
        
                // go to next
                if(direction < 0){
                    setChangeable(0);
                    currentIndex>=(keys.length-1)? setChangeable(1):handleClick(keys[currentIndex+1]);

                // previous
                }else if(direction > 0){
                    setChangeable(0);
                    currentIndex===0? setChangeable(1):handleClick(keys[currentIndex-1]);
                }
            }
        }

    }

    const handleEnd = (e) => {
        // console.log('END')
        let currentPoint = e.nativeEvent.pageX;
        let direction = currentPoint - startPoint
        let distance = Math.abs(direction)

        // current - start < 0 => go to next
        // current - start > 0 => previous
        // console.log(`starting point: ${startPoint.toFixed(2)} \ncurrent point: ${currentPoint.toFixed(2)} \ndirection: ${direction.toFixed(2)} \nthreshold: ${threshold}`)

            if(distance > threshold){
                let currentIndex;
                let keys = Object.keys(isClicked);
                Object.keys(isClicked).forEach((key, index) => {
                    if(isClicked[key]) currentIndex = index;
                });
                // console.log(`current index: ${currentIndex} keys: ${keys[currentIndex]}`)
        
                // go to next
                if(direction < 0){
                    setChangeable(1);
                    currentIndex>=(keys.length-1)? null:handleClick(keys[currentIndex+1]);

                // previous
                }else if(direction > 0){
                    setChangeable(1);
                    currentIndex===0? null:handleClick(keys[currentIndex-1]);
                }
            }
        
    }


    return (
        <View style={styles(styleWidth).container}>
            <Text style={styles(styleWidth).title}>Our Services</Text>
            <ScrollView 
                ref={(ref) => {myRef.current = ref}} 
                horizontal={true} 
                showsHorizontalScrollIndicator={false} 
                style={{paddingTop:normalize(20,styleWidth), flexGrow: 1, width: normalize(640, styleWidth)}}
            >
                <Pressable 
                    onLayout={event => {handleOnLayout(event, 'a')}} 
                    onPress={() => {handleClick('a')}}
                >
                    <Text style={isClicked.a? styles(styleWidth).optionClick:styles(styleWidth).option}>
                        {serviceTitle[0]}
                    </Text>
                </Pressable>

                <Pressable 
                    onLayout={event => {handleOnLayout(event, 'b')}} 
                    onPress={() => handleClick('b')}
                >    
                    <Text style={isClicked.b? styles(styleWidth).optionClick:styles(styleWidth).option}>
                        {serviceTitle[1]}
                    </Text>
                </Pressable>
                    
                <Pressable 
                    onLayout={event => {handleOnLayout(event, 'c')}} 
                    onPress={() => handleClick('c')}
                >
                    <Text style={isClicked.c? styles(styleWidth).optionClick:styles(styleWidth).option}>
                        {serviceTitle[2]}
                    </Text>
                </Pressable>
                    
                <Pressable 
                    onLayout={event => {handleOnLayout(event, 'd')}} 
                    onPress={() => handleClick('d')}
                >
                    <Text style={isClicked.d? styles(styleWidth).optionClick:styles(styleWidth).option}>
                        {serviceTitle[3]}
                    </Text>
                </Pressable>
            </ScrollView>

            <View
                onStartShouldSetResponder={(e)=>true}
                style={{marginTop:normalize(50, styleWidth)}}
                onResponderStart={(e)=>handleStart(e)}
                onResponderMove={(e)=>handleMoving(e)}
                onResponderEnd={(e)=>handleEnd(e)}
            >
                <Animated.Image 
                    source={imageSrc} 
                    style={[
                        styles(styleWidth).img,
                        { opacity: fade1 }
                    ]} 
                    resizeMode='contain'
                />
                
                <Animated.View 
                    style={[
                        styles(styleWidth).descBox,
                        {
                            opacity: fadeAnim,
                            transform: [{
                                translateX: fadeAnim.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [30, 0]
                                })
                            }]
                        }
                    ]}
                >
                    <Desc content={content}/>
                </Animated.View>
            </View>        
        </View>
    )   
}

const styles = (width) => StyleSheet.create({
    container:{
        padding:normalize(80,width),
        paddingTop:normalize(100,width),
        backgroundColor: '#FFFFFF',
        maxWidth:width
    },
    title: {
        fontFamily:'Raleway-ExtraBold',
        color:'#0F0F0F',
        fontSize:normalize(60,width)
    },
    contentBox:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'  
    },
    option:{
        padding:normalize(15,width), 
        fontFamily:'Raleway-Medium', 
        fontSize:normalize(18,width),
        color:'#AAAAAA'
    },
    optionClick:{
        fontFamily: 'Raleway-Bold',
        fontSize: normalize(18,width),
        color: '#0F0F0F',
        borderBottomWidth: normalize(4,width),
        borderBottomColor:'#FF9F1D',
        padding:normalize(15,width),
        paddingLeft:0,
        paddingRight:0,
        flexShrink:1
    },
    img: {
        width:'95%',
        height:normalize(550,width),
        margin:'auto'
    },
    descBox:{
        width: '100%',
        backgroundColor:'#FF9F1D',
        justifyContent:'center',
        padding:normalize(35,width),
        paddingRight:normalize(10,width),
        shadowColor: '#FF9F1D',
        shadowOffset:{width:0, height:20},
        shadowRadius: 40,
        shadowOpacity: 0.25,
        margin:'auto'
    },
    smalldot: {
        width:normalize(10,width),
        height:normalize(10,width),
        backgroundColor: '#0F0F0F',
        margin:'auto',
        marginLeft:normalize(10,width),
        marginRight:normalize(5,width),
        alignItems:'center',
    },
    descContent: {
        fontFamily:'Raleway-Medium',
        fontSize: normalize(20,width),
        color:'#FFFFFF',
        padding:normalize(20,width)
    }

})