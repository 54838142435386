import { StyleSheet } from "react-native";
import { normalize } from "../utils/normalize";

export const styles = (width) => StyleSheet.create({
    container: {
        width:'100%', 
        alignContent:'center', 
        display:'flex', 
        flexWrap:'wrap', 
        alignSelf:'center', 
        maxWidth:width,
        // backgroundColor:'#F9F9F9'
      },
      contentBox: {
        paddingTop:normalize(100, width)
      },
      backToTop: {
        right:normalize(60,width),
        bottom: normalize(70,width),
        position:'fixed',
        width:normalize(60,width),
        height:normalize(60,width),
        backgroundColor:'#EB7300',
        alignItems:'center',
        justifyContent:'center',
        // shadowColor: '#FF9F1D',
        shadowColor: '#EB7300',
        shadowOffset:{width:0, height:50},
        shadowRadius: 100,
        shadowOpacity: 0.3,
        borderWidth: 5,
        borderColor: '#FFFFFF'
    },
    backToTop_btn: {
        width:normalize(20,width),
        height:normalize(20,width), 
        backgroundColor: '#EB7300', 
        tintColor: '#FFFFFF'
    }
})