import React, {useEffect, useRef, useState} from 'react';
import { Animated, ImageBackground, View, Text, Image, StyleSheet, Dimensions } from 'react-native';
import { normalize } from '../utils/normalize';
import { animateTwoTimes } from '../utils/animation';
import homeImg from '../assets/image/img-01.png';
import bg1 from '../assets/image/bg-01.png';
import titlebg from '../assets/image/img-02.png';

// import trucking from '../assets/image/img-03.png';
import cargoStorage from '../assets/image/img-04.png';
// import warehousing from '../assets/image/img-05.png';
import freight from '../assets/image/img-06.png';

import trucking from '../assets/image/trucking.png';
import warehousing from '../assets/image/warehouse-03.png';

import price from '../assets/image/img-08.png';
import staff from '../assets/image/img-09.png';
import needs from '../assets/image/img-10.png';
import quality from '../assets/image/img-11.png';

const maxWidth = 1280;

const chooseUsContent = [
    {
        src: price,
        text: "Affordable Price"
    },
    {
        src: staff,
        text: "Experienced and Proactive Staffs"
    },
    {
        src: needs,
        text: "Cater To Customer's Needs"
    },
    {
        src: quality,
        text: "Quality Services"
    },
]

const introContent = [
    {
        src: trucking,
        direction:'left',
        num: '01',
        title: "Trucking",
        desc: 'For any relocation purposes or delivery of products, we provide efficient and "deliveries to your doorstep" trucking services.'
    },
    {
        src: cargoStorage,
        direction:'right',
        num: '02',
        title: "Cargo Storage",
        desc: 'We are offering a temporary depository for customers to store their products and goods as we understand that warehouses can quickly run out of space.'
    },
    {
        src: warehousing,
        direction:'left',
        num: '03',
        title: "Warehousing",
        desc: "To truncate the delivery time frame and provide an affordable price, we have readily available facilities to handle customers' supply chain. "
    },
    {
        src: freight,
        direction:'right',
        num: '04',
        title: "Air Freight/Sea Freight",
        desc: 'Venturing into international logistics services to provide our customers with comprehensive and reliable solutions.'
    },

]

function ChooseUs(props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const fade3 = useRef(new Animated.Value(0)).current;
    const fade4 = useRef(new Animated.Value(0)).current;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }

    }

    useEffect(() => {
        if(props.visible && props.onTop) animateTwoTimes(fade3,fade4);
    }, [props.visible, props.onTop]);
    
    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);

        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    return(
        <View style={{flexDirection:'column'}}>
            <Animated.View 
                style={{
                    opacity: fade3,
                    transform: [{
                        translateY: fade3.interpolate({
                            inputRange: [0, 1],
                            outputRange: [75, 0]
                        })
                    }]
                }}
            >
                <View style={styles(styleWidth).chooseUsImgBox}>
                    <Image source={props.content.src} style={styles(styleWidth).chooseUsImg}></Image>
                </View>
            </Animated.View>
            
            <Animated.View 
                style={{
                    opacity: fade4,
                    transform: [{
                        translateX: fade4.interpolate({
                            inputRange: [0, 1],
                            outputRange: [-100, 0]
                        })
                    }]
                }}
            >
                <Text style={styles(styleWidth).bordertext}>{props.content.text}</Text>
            </Animated.View>
        </View>
    )
}

function Intro(props) {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let height = Dimensions.get('window').height;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const fade5 = useRef(new Animated.Value(0)).current;
    const fade6 = useRef(new Animated.Value(0)).current;
    const [visible, setVisible] = useState(0);
    const [ot, setOT] = useState(props.onTop);

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            height = Dimensions.get('window').height;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }
    
    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);

        return () => {
          // executed when unmount
          isMounted = false;
        }

    }, []);

    useEffect(() => {
        const changeOnTop = async () => {
            setOT(1);
        }
        if(props.onTop) changeOnTop();

    },[props.onTop])

    useEffect(() => {
        if(visible && ot) animateTwoTimes(fade5, fade6);
    },[visible, ot])

    const handleOnLayout = (e) => {
        let layout = e.nativeEvent.layout;

        if(layout.y > height){
            window.addEventListener('scroll', () => {
                if(window.pageYOffset > (layout.y-height+20) && ot) {
                    setVisible(1);
                }
            
            })
        }else if(layout.y >= 0 && layout.y < height){
            setVisible(1)
        }
    }

    let title = props.content.title;
    let a = title.length;
    let firstChar = title[0];
    let str = title.slice(1, a)

    if(props.content.direction == 'left') {
        return(
            <View onLayout={e => {handleOnLayout(e)}} key = {ot} style={styles(styleWidth).intro}>
                <Animated.Image 
                    source={props.content.src} 
                    style={[
                        styles(styleWidth).introimg,
                        {
                            opacity:fade5,
                            transform: 
                            [{
                                translateY: fade5.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [75, 0]
                                })
                            }]
                        }
                    ]} 
                    resizeMode='contain' 
                />

                <Animated.View 
                    style={[
                        styles(styleWidth).introtextRight,
                        {
                            opacity:fade6,  
                            transform: 
                            [{
                                translateX: fade6.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [200, 0]
                                })
                            }]
                        }
                    ]}
                >
                    <Text style={styles(styleWidth).num}>{props.content.num}</Text>
                    <Text style={styles(styleWidth).title}><Text style={{borderBottomWidth:5, borderColor:'#FF9F1D',paddingBottom:5}}>{firstChar}</Text>{str}<Text></Text></Text>
                    <Text style={styles(styleWidth).introcontent}>{props.content.desc}</Text>
                </Animated.View>
            </View>
        )

    }else if(props.content.direction =='right'){
        return(
            <View onLayout={e => {handleOnLayout(e)}} key={ot} style={styles(styleWidth).intro}>
                <Animated.View 
                    style={[
                        styles(styleWidth).introtextLeft,
                        {
                            opacity:fade6,  
                            transform: 
                            [{
                                translateX: fade6.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [-100, 0]
                                })
                            }]
                        }
                    ]}
                >
                    <Text style={styles(styleWidth).num}>{props.content.num}</Text>
                    <Text style={styles(styleWidth).title}><Text style={{borderBottomWidth:5, borderColor:'#FF9F1D',paddingBottom:5}}>{firstChar}</Text>{str}<Text></Text></Text>
                    <Text style={styles(styleWidth).introcontent}>{props.content.desc}</Text>
                </Animated.View>

                <Animated.Image 
                    source={props.content.src} 
                    style={[
                        styles(styleWidth).introimg,
                        {
                            opacity:fade5,
                            transform: 
                            [{
                                translateY: fade5.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [75, 0]
                                })
                            }]
                        }
                    ]} 
                    resizeMode='contain' 
                />
            </View>
        )
    }
}

//Home main function
function Home() {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let height = Dimensions.get('window').height;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            height = Dimensions.get('window').height;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    const fadeAnim = useRef(new Animated.Value(0)).current;
    const fade1 = useRef(new Animated.Value(0)).current;
    const [visible, setVisible] = useState(0);
    const [onTop, setOnTop] = useState(0);

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        if(window.pageYOffset > 0){
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
            window.addEventListener('scroll', () => {
                if(window.pageYOffset == 0){
                    setOnTop(1);
                    animateTwoTimes(fade1, fadeAnim);
                    return;
                }
            })
        }else{
            setOnTop(1);
            animateTwoTimes(fade1, fadeAnim);
        }

        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    const handleOnLayout = (e) => {
        let layout = e.nativeEvent.layout;

        if(layout.y > 0){
            if(layout.y > height){
                window.addEventListener('scroll', () => {
                    if(onTop){
                        if((window.pageYOffset > (layout.y-height+250))) {
                            setVisible(1);
                        }
                    }
                })
            }else{
                setVisible(1);
            }
        }
    }

    const chooseUs = chooseUsContent.map((con) => 
        <ChooseUs key={chooseUsContent.indexOf(con)} content={con} visible={visible} onTop={onTop}/>
    );

    const intro = introContent.map((introCon) =>
        <Intro key={introCon.num} content={introCon} onTop={onTop} />
    );
    
    return (
        //for big container
        <View style={styles(styleWidth).container}>
            {/*for title*/}
            <View style={styles(styleWidth).titlebox}>
                <Animated.View 
                    style={{
                        opacity: fadeAnim,
                        transform: [{
                            translateX: fadeAnim.interpolate({
                                inputRange: [0, 1],
                                outputRange: [-100, 0]
                            })
                        }], 
                        zIndex:5
                    }}
                >
                    <ImageBackground source={titlebg} resizeMode='cover' style={styles(styleWidth).hometitle}>
                        <Text style={styles(styleWidth).safeAndFast}>Save, Fast &</Text>
                        <Text style={styles(styleWidth).reliable}>Reliable</Text>
                        <Text style={styles(styleWidth).globallog}>Global Logistics Services</Text>
                    </ImageBackground>
                </Animated.View>

                <Animated.View 
                    style={{
                        opacity: fade1,
                        transform: [{
                            translateY: fade1.interpolate({
                                inputRange: [0, 1],
                                outputRange: [75, 0]
                            })
                        }]
                    }}
                >
                    <Image source={homeImg} style={styles(styleWidth).homeimg} resizeMode='contain' />
                </Animated.View>
            </View>

            {intro}
              
            <View onLayout={e => {handleOnLayout(e)}} key={onTop} style={{flex:1}}>
                <ImageBackground source={bg1} resizeMode='cover' style={styles(styleWidth).chooseusbg}>
                    <Text style={styles(styleWidth).chooseUsTitle}>Why Choose Us </Text>
                    <View style={styles(styleWidth).chooseUsBox}>
                        {chooseUs}
                    </View>
                </ImageBackground>
            </View>
        </View>
    )
}

const styles = (width) => StyleSheet.create({
    container: {
        width:'100%',
        margin:'auto',
        flexDirection:'column',
        justifyContent:'center',
        backgroundColor:'#FFFFFF'
    },
    titlebox:{
        backgroundColor:'#E8E8E8',
        paddingTop:normalize(80,width),
        paddingBottom:normalize(80,width)
    },
    homeimg: {
        alignSelf: 'flex-end',
        flex:1,
        width: '65%',
        height:normalize(550,width),
        marginRight:normalize(80,width),
    },
    hometitle:{
        zIndex:2,
        justifyContent:'center',
        //alignItems:'center',
        marginLeft:normalize(120,width),
        marginTop: normalize(80,width),
        paddingLeft:normalize(100,width),
        backgroundColor: '#FF9F1D',
        position:'absolute', 
        width:normalize(610,width), 
        height:normalize(400,width),
        shadowColor: '#FF9F1D',
        shadowOffset:{width:0, height:20},
        shadowRadius: 40,
        shadowOpacity: 0.25
    },
    safeAndFast:{
        color:'#FFFFFF',
        fontFamily:'Raleway-Bold',
        fontSize:normalize(50,width),
        textAlign:'left'

    },
    reliable:{
        color:'#FFFFFF',
        fontFamily:'Raleway-Bold',
        fontSize:normalize(80,width),
        textAlign:'left'
    },
    globallog:{
        color:'#FFFFFF',
        fontFamily:'Raleway-Medium',
        fontSize:normalize(30,width),
        textAlign:'left'
    },
    titleword:{
        flex:1,
        backgroundColor: '#FF9F1D',
        width: '40%',
        alignSelf: 'flex-start',
        margin:'auto',
        marginLeft: normalize(55,width),
        position: 'absolute',
        opacity: 0.95,
        zIndex: 2
    },
    text:{
        fontFamily:'Raleway-Medium',
        color: '#FFFFFF'
    },
    intro: {
        flexDirection:'row',
        justifyContent:'center',
        width:'100%',
        paddingLeft:normalize(120,width),
        paddingRight:normalize(120,width),
    },
    introimg:{
        width:'35%',
        height:normalize(550,width),
        shadowColor: "#AAAAAA",
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        overflow: 'visible',
        elevation: 14,
    },
    introtextRight:{
        flexDirection:'column', 
        justifyContent:'center', 
        paddingLeft:normalize(120,width),
        flexShrink:1
    },
    introtextLeft:{
        flexDirection:'column', 
        justifyContent:'center', 
        paddingRight:normalize(120,width),
        flexShrink:1
    },
    num: {
        fontSize:normalize(80,width),
        fontFamily: 'Raleway-Bold',
        color: '#FF9F1D'
    },
    title:{
        fontSize:normalize(35,width),
        fontFamily: 'Raleway-Bold',
        color: '#000000',
        borderColor:'#FF9F1D'
    },
    introcontent:{
        fontSize:normalize(25,width),
        fontFamily: 'Raleway-Medium',
        color: '#AAAAAA',
        paddingTop: normalize(50,width)
    },
    chooseusbg:{
        justifyContent:'center',
    },
    chooseUsImg:{
        width:normalize(100,width), 
        height:normalize(100,width)
    },
    chooseUsTitle:{
        fontFamily:'Raleway-ExtraBold', 
        fontSize:normalize(60,width), 
        alignSelf:'center', 
        margin:normalize(100,width)
    },
    chooseUsBox:{
        flexDirection:'row',
        justifyContent: 'space-around',
        marginLeft:normalize(80,width), 
        marginRight:normalize(80,width)
    },
    bordertext:{
        borderLeftWidth: normalize(5,width),
        borderColor:'#FF9F1D',
        marginBottom: normalize(150,width),
        width:normalize(250,width),
        fontFamily:'Raleway-Bold',
        fontSize:normalize(22,width),
        textAlign:'left',
        paddingLeft: normalize(15,width),
    },
    chooseUsImgBox: {
        backgroundColor:'#FFFFFF',
        width:normalize(250,width), 
        height:normalize(250,width), 
        alignItems:'center',
        justifyContent:'center',
        marginBottom:normalize(50,width)
    }
    
})

export default Home
