import { PixelRatio, Dimensions } from 'react-native';

// Normalize the size of style -> responsive
export function normalize(size, width) {
    // Check the screen size of the device
    // If width larger than 800 -> maxWidth: 1280 (web view)
    // If smaller than 800 -> maxWidth: 800 (mobile view)
    const maxWidth = width > 800? 1280:800;
    const scale = width / maxWidth;
    const newSize = size * scale;

    return (Math.round(PixelRatio.roundToNearestPixel(newSize)) + 2) ;       
}