import React, {useEffect, useState, useRef} from 'react';
import { Animated, View, Text, StyleSheet, Pressable, Dimensions } from 'react-native';
import { normalize } from '../utils/normalize';
import { animateTwoTimes, serviceAnimate } from '../utils/animation';
import serviceA from '../assets/image/img-12.png';
import serviceB from '../assets/image/img-13.png';
import serviceC from '../assets/image/img-14.png';
import serviceD from '../assets/image/img-15.png';

const maxWidth = 1280;

const imgSrc = [serviceA, serviceB, serviceC, serviceD];
const serviceTitle = ['Sea Freight International & Domestic (East Malaysia)',
                        'Land Freight', 'Air Freight International',
                        'Custom Brokerage'];
const serviceContent = {
    a:['FCL Import And Export','LCL Import And Export'],
    b:['Domestic Delivery', 'Cross Border Trucking', 
        'Project Transportation (low loader, crane)','Container Haulage'],
    c:['Consolidation', 'Chartered / Freighter'],
    d:['Export / Import Declaration', 'SG Permit Declaration']
};

function Option(props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    return(  
        <View style={{width:normalize(350, styleWidth)}}>
            <Text style={props.isClicked? styles(styleWidth).optionClick:styles(styleWidth).option}>
                {props.text}
            </Text>
        </View>
    )
}

function Desc(props){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);

    const content = props.content;
    
    const listContent = content.map((con) => 
        <View key={con} style={{flexDirection:'row'}}>
            <View style={styles(styleWidth).smalldot} />
            <Text style={styles(styleWidth).descContent}>{con}</Text>
        </View>
    );
    return(
        <View>
            {listContent}
        </View>
    )
}

export default function Services(){
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;
    let fadeAnim = useRef(new Animated.Value(0)).current;
    let fade1 = useRef(new Animated.Value(0)).current;

    const [isClicked, setClicked] = useState({
        a: true,
        b: false,
        c: false,
        d: false
    })
    const [imageSrc, setImageSrc] = useState(imgSrc[0]);
    const [content, setContent] = useState(serviceContent['a']);
    const [onTop, setOnTop] = useState(0);

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }

    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        window.addEventListener('resize', handleResize);
        if(window.pageYOffset > 0){
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
            window.addEventListener('scroll', () => {
                if(window.pageYOffset == 0){
                    setOnTop(1);
                    return;
                }
            })
        }else{
            setOnTop(1);
        }

        return () => {
          // executed when unmount
          isMounted = false;
        }
    }, []);
    
    // for transition
    useEffect(() => {
        //reset the value of opacity
        fade1.setValue(0);
        fadeAnim.setValue(0);

        if(onTop) serviceAnimate(fade1, fadeAnim, 300, 350);

    }, [fade1, fadeAnim, content, onTop]);


    const handleClick = (index) => {
        let newState = isClicked;
        let newSrc, newContent;
        let name = Object.keys(newState);

        for(let a = 0; a< name.length; a++) {
            newState[name[a]] = (index == name[a])? true:false
        }

        for(let b = 0; b < name.length; b++) {
            if(index == name[b]){
                newSrc = imgSrc[b]
                newContent = serviceContent[index];
                break;
            }
        }

        setClicked(newState);
        setImageSrc(newSrc);
        setContent(newContent);
    }

    return (
        <View style={styles(styleWidth).container}>
            <Text style={styles(styleWidth).title}>Our Services</Text>

            <View style={styles(styleWidth).contentBox}>
                <View>
                    <Pressable onPress={() => handleClick('a')}>
                        <Option isClicked={isClicked.a} text={serviceTitle[0]} />
                    </Pressable>

                    <Pressable onPress={() => handleClick('b')}>
                        <Option isClicked={isClicked.b} text={serviceTitle[1]} />
                    </Pressable>

                    <Pressable onPress={() => handleClick('c')}>
                        <Option isClicked={isClicked.c} text={serviceTitle[2]} />
                    </Pressable>

                    <Pressable onPress={() => handleClick('d')}>
                        <Option isClicked={isClicked.d} text={serviceTitle[3]} />
                    </Pressable>
                </View>
                    
                <Animated.Image 
                    source={imageSrc} 
                    style={[
                        styles(styleWidth).img,
                        {
                            opacity: fade1,
                            // transform:[{
                            //     translateY: fade1.interpolate({
                            //         inputRange: [0, 1],
                            //         outputRange: [75, 0]
                            //     })
                            // }]
                        }
                    ]} 
                    resizeMode='contain'
                />
                
                <Animated.View 
                    style={[
                        styles(styleWidth).descBox,
                        {
                            opacity: fadeAnim,
                            transform:[{
                                translateX: fadeAnim.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [100, 0]
                                })
                            }]
                        }
                    ]}
                >
                    <Desc content={content}/>
                </Animated.View>
            </View>
        </View>
    )
}

const styles = (width) => StyleSheet.create({
    container:{
        padding:normalize(80,width),
        paddingTop:normalize(100,width),
        backgroundColor: '#FFFFFF'
    },
    title: {
        fontFamily:'Raleway-ExtraBold',
        color:'#0F0F0F',
        fontSize:normalize(60,width)
    },
    contentBox:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'  
    },
    option:{
        fontFamily: 'Raleway-Medium',
        fontSize: normalize(20,width),
        color: '#AAAAAA',
        borderLeftWidth: normalize(4,width),
        borderLeftColor:'#AAAAAA',
        padding:normalize(20,width),
        paddingRight:0,
        flexShrink:1
    },
    optionClick:{
        fontFamily: 'Raleway-Bold',
        fontSize: normalize(20,width),
        color: '#0F0F0F',
        borderLeftWidth: normalize(5,width),
        borderLeftColor:'#FF9F1D',
        padding:normalize(20,width),
        paddingRight:0,
        flexShrink:1
    },
    img: {
        width:normalize(450,width),
        height:normalize(450,width)
    },
    descBox:{
        width: '30%',
        backgroundColor:'#FF9F1D',
        justifyContent:'center',
        padding:normalize(25,width),
        paddingRight:normalize(10,width),
        shadowColor: '#FF9F1D',
        shadowOffset:{width:0, height:20},
        shadowRadius: 40,
        shadowOpacity: 0.25
    },
    smalldot: {
        width:normalize(10,width),
        height:normalize(10,width),
        backgroundColor: '#0F0F0F',
        margin:'auto',
        marginLeft:normalize(10,width),
        marginRight:normalize(5,width),
        alignItems:'center',
    },
    descContent: {
        fontFamily:'Raleway-Medium',
        fontSize: normalize(20,width),
        color:'#FFFFFF',
        padding:normalize(20,width)
    }

})

