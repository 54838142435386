import React, { useEffect, useRef, useState } from 'react'
import { Button, Animated, Picker, View, Text, ImageBackground, StyleSheet, TextInput, Pressable, Dimensions, DrawerLayoutAndroidBase} from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { normalize } from '../utils/normalize';
import { animateOneTimes } from '../utils/animation';
import axios from 'axios';
import * as data from '../utils/country_code.json';
import bg from '../assets/image/bg-04.png'

const maxWidth = 1280;
const content='Drop us a message if you have any enquiries. We will be glad to assist you.'
const url = 'https://acaglobalportal.com/api/contactus';

export default function ContactUs() {
    let isMounted = false;
    let width = Dimensions.get('window').width ;
    let styleWidth = width >= maxWidth? maxWidth:width;
    const [pickerList, setPickerList] = useState('');
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const handleResize = () => {
        if(isMounted){
            width = Dimensions.get('window').width;
            styleWidth = width >= maxWidth? maxWidth:width;
        }
    }
    
    useEffect(() => {
        // executed when component mounted
        isMounted = true;
        getPickerList(data.countries);
        window.addEventListener('resize', handleResize);
        if(window.pageYOffset > 0){
            window.scrollTo({
                top:0,
                behavior: 'smooth'
            });
            window.addEventListener('scroll', () => {
                if(window.pageYOffset == 0){
                    animateOneTimes(fadeAnim);
                    return;
                }
            })
        }else{
            animateOneTimes(fadeAnim);
        }

        return () => {
          // executed when unmount
            isMounted = false;
        }
    }, [fadeAnim]);

    const getPickerList = (data) => {
        let all_code = []
        data.map((item)=>all_code.push(item.code));

        // sort the code by number and delete the duplicate data
        let sorting_code=all_code.sort();
        let unique_code = [...new Set(sorting_code)];
        
        const pList = unique_code.map((code) => {
            return(
                <Picker.Item label={code} value={code} key={code} />
            )
        })

        setPickerList(pList);
    }
    const defaultValues ={
        name: '',
        email:'',
        country:'+60',
        contact:'',
        organization:'',
        message:''
    };

    const { register, setValue, handleSubmit, control,reset, formState: { errors } } = useForm({
        defaultValues: defaultValues
    });

    const onSubmit = async(data) => {     
        if(!data.name ||!data.email || !data.contact || !data.message){
            alert("Field cannot be empty!")
            return;
        }

        let payload = {
            name:data.name,
            email:data.email,
            country:data.country,
            contact:data.contact,
            organization:data.organization,
            message:data.message
        };
        try{
            const res = await axios({
                method:'post',
                url:url,
                data: payload
            })

            if(res.status === 200){
                alert('Submit successfully');
                reset(defaultValues);
            };
        }catch(error){
            console.log(error);
            alert('Email is invalid!');
        }
    };

    const onChange = arg => {
        return {
            value: arg.nativeEvent.text,
        };
    };

    return(
        <ImageBackground source={bg} resizeMode='cover' style={{width:'100%', margin:'auto'}} >
            <Animated.View style={{opacity: fadeAnim}}>
                <View style={styles(styleWidth).container}>
                    <View style={styles(styleWidth).infoContainer}>
                        <Text style={styles(styleWidth).title}>Contact Us</Text>
                        <Text style={styles(styleWidth).content}>{content}</Text>

                        <View style={styles(styleWidth).infoRow}>
                            <View style={styles(styleWidth).infoColumn}>
                                <Text style={styles(styleWidth).infoTitle}>Name</Text>
                                <Controller
                                    control={control}
                                    render={({field:{onChange, onBlur, value}}) => (
                                        <TextInput
                                            style={styles(styleWidth).input}
                                            onBlur={onBlur}
                                            onChangeText={value => onChange(value)}
                                            value={value}
                                            placeholder='Enter your name...'
                                            placeholderTextColor = "#E8E8E8"
                                        />
                                    )}
                                    name="name"
                                    // rules={{ required: true}}
                                />
                            </View>

                            <View style={styles(styleWidth).infoColumn}>
                                <Text style={styles(styleWidth).infoTitle}>Email</Text>
                                <Controller
                                    control={control}
                                    render={({field:{onChange, onBlur, value}}) => (
                                        <TextInput
                                            onBlur={onBlur}
                                            onChangeText={value => onChange(value)}
                                            value={value}
                                            placeholder='Enter your email...'
                                            placeholderTextColor = "#E8E8E8"
                                            style={styles(styleWidth).input}
                                        />
                                    )}
                                    name="email"
                                    // rules={{ required: true}}
                                />
                            </View>
                        </View>

                        {/* second row */}
                        <View style={{flexDirection:'row', padding:normalize(10, styleWidth)}}>
                            <View style={{flexDirection:'row', flex:1, justifyContent:'space-between'}}>
                                <View style={{flexDirection:'column', flex:0.3}}>
                                    <Text style={styles(styleWidth).infoTitle}>Country</Text>
                                    <Controller 
                                        control={control}
                                        render={({field:{onChange, onBlur, value}})=>(
                                            <Picker
                                                selectedValue={value}
                                                style={styles(styleWidth).pickerStyle}
                                                onValueChange={(itemValue, itemIndex)=>onChange(itemValue)}
                                                onBlur={onBlur}
                                            >

                                                {pickerList}
                                                {/* <Picker.Item label="+60" value="+60" />
                                                <Picker.Item label="+65" value="+65" />
                                                <Picker.Item label="+81" value="+81" /> */}
                                            </Picker>
                                        )}
                                        name="country"
                                        // rules={{ required: true }}
                                    />
                                </View>

                                <View style={{flexDirection:'column', flex:0.7}}>
                                    <Text style={styles(styleWidth).infoTitle}>Contact No.</Text>
                                    <Controller
                                        control={control}
                                        render={({field:{onChange, onBlur, value}}) => (
                                            <TextInput
                                            style={styles(styleWidth).input}
                                                onBlur={onBlur}
                                                onChangeText={value => onChange(value)}
                                                value={value}
                                                placeholder='Enter your contact no...'
                                                keyboardType="numeric"
                                                placeholderTextColor = "#E8E8E8"
                                            />
                                        )}
                                        name="contact"
                                        // rules={{required: true}}
                                    />
                                </View>
                            </View>
                    
                            <View style={styles(styleWidth).infoColumn}>
                                <Text style={styles(styleWidth).infoTitle}>Organization Name <Text style={{color:"#E8E8E8"}}>(if applicable)</Text></Text>
                                <Controller
                                    control={control}
                                    render={({field:{onChange, onBlur, value}}) => (
                                        <TextInput
                                            style={styles(styleWidth).input}
                                            onBlur={onBlur}
                                            onChangeText={value => onChange(value)}
                                            value={value}
                                            placeholder='Enter your organization name...'
                                            placeholderTextColor = "#E8E8E8"
                                        />
                                    )}
                                    name="organization"
                                    // rules={{ required: false}}
                                />
                            </View>
                        </View>

                        {/* third row */}
                        <View style={styles(styleWidth).infoColumn, {padding:normalize(10,styleWidth)}}>
                            <Text style={styles(styleWidth).infoTitle}>Message</Text>
                            <Controller
                                control={control}
                                render={({field:{onChange, onBlur, value}}) => (
                                    <TextInput
                                        style={styles(styleWidth).input}
                                        onBlur={onBlur}
                                        onChangeText={value => onChange(value)}
                                        value={value}
                                        placeholder='Enter your message...'
                                        placeholderTextColor = "#E8E8E8"
                                    />
                                )}
                                name="message"
                                // rules={{ required: true}}
                            />
                        </View>
                    </View>

                    <Pressable style={styles(styleWidth).submitBtn}onPress={handleSubmit(onSubmit)}>
                        <Text style={styles(styleWidth).submitBtnInner}>Submit</Text>
                    </Pressable>                     
                </View>
            </Animated.View>
        </ImageBackground>
    )
}

const styles = (width) => StyleSheet.create({
    container: {
        width:'75%',
        backgroundColor:'#FFFFFF',
        margin:'auto',
        marginBottom:normalize(50,width),
        marginTop:normalize(75,width), 
        padding:normalize(80,width),
        paddingBottom:0, 
        paddingRight:0,
        paddingTop:normalize(65,width),
        shadowColor: "#AAAAAA",
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        overflow: 'visible',
        elevation: 14,
    },
    infoContainer: {
        paddingRight:normalize(90,width)
    },
    title:{
        fontSize:normalize(60,width), 
        fontFamily:'Raleway-ExtraBold', 
        padding:normalize(10,width),
        paddingTop:0
    },
    content:{
        fontSize:normalize(20,width), 
        fontFamily:'Raleway-Medium', 
        padding:normalize(10,width), 
        paddingBottom:normalize(20,width), 
        color:'#AAAAAA'
    },
    infoRow: {
        flexDirection:'row',
        justifyContent:'space-between',
        padding:normalize(10,width)
    },
    infoColumn: {
        flexDirection:'column',
        justifyContent:'space-between',
        flex:1
    },
    infoTitle:{
        fontFamily:'Raleway-Medium',
        fontSize:normalize(18,width),
        color:'#AAAAAA'
    },
    input: {
        height: normalize(50,width),
        margin: normalize(20,width),
        marginTop:normalize(10,width),
        marginLeft: 0,
        marginBottom:normalize(40,width),
        borderWidth: 1,
        padding: normalize(20,width),
        fontFamily:'Raleway-Medium',
        borderColor:'#E8E8E8',
        fontSize:normalize(18,width)
    },
    errorInput: {
        height: normalize(50,width),
        margin: normalize(20,width),
        marginTop:normalize(10,width),
        marginLeft: 0,
        marginBottom:normalize(40,width),
        borderWidth: 1,
        padding: normalize(20,width),
        fontFamily:'Raleway-Medium',
        borderColor:'#DE3C6E',
        fontSize:normalize(18,width)
    },
    pickerStyle: {
        height:normalize(50,width),
        margin: normalize(20,width),
        marginTop:normalize(10,width),
        marginBottom:normalize(40,width),
        marginLeft: 0,
        borderWidth: 1, 
        borderColor:"#E8E8E8", 
        fontFamily:'Raleway-Medium', 
        fontSize:normalize(18,width), 
        padding:normalize(5,width)
    },
    submitBtn:{
        alignSelf:'flex-end',
        backgroundColor:'#FF9F1D',
        paddingHorizontal:normalize(100,width),
        paddingVertical:normalize(20,width),
        shadowColor: '#FF9F1D',
        shadowOffset:{width:0, height:20},
        shadowRadius: 40,
        shadowOpacity: 0.25
    },
    submitBtnInner:{
        fontSize:normalize(25,width),
        fontFamily:'Raleway-Bold',
        color:'#FFFFFF',
    }
  });